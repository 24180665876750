import api from '../../api';
import { setAlert } from './alertActions';
import { ACCESS_MANAGEMENT } from '../constants';


export const getAllUserPermissions = () => async (dispatch) => {
  try {
    dispatch({ type: ACCESS_MANAGEMENT.GET_USER_PERMISSIONS });
    const { data } = await api.accessManagement.getAllUserPermissions();
    dispatch({ type: ACCESS_MANAGEMENT.GET_USER_PERMISSIONS_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACCESS_MANAGEMENT.GET_USER_PERMISSIONS_FAILURE, payload: error });
    dispatch(
      setAlert({
        title: 'Failed to fetch user permissions',
        status: 'error'
      })
    );
  }
};

export const updateUserPermissions = ({ name, userId, roles, onSuccess, onError }) => async (dispatch) => {
  try {
    dispatch({ type: ACCESS_MANAGEMENT.UPDATE_USER_PERMISSIONS });
    await api.accessManagement.updateUserPermissions(userId, {
      name: name,           // Backend expects "name"
      user_id: userId,      // Backend expects "user_id" (snake case)
      roles: roles          // Backend expects "roles" as a list of strings
    });
    dispatch({
      type: ACCESS_MANAGEMENT.UPDATE_USER_PERMISSIONS_SUCCESS,
      payload: { name, userId, roles },
    });
    onSuccess();
  } catch (error) {
    dispatch({
      type: ACCESS_MANAGEMENT.UPDATE_USER_PERMISSIONS_FAILURE,
      payload: error,
    });
    onError(error);
  }

  
};

export const getAllRoles = () => async (dispatch) => {
  try {
    dispatch({ type: ACCESS_MANAGEMENT.GET_ALL_ROLES });
    const { data } = await api.accessManagement.getAllRoles();
    dispatch({ type: ACCESS_MANAGEMENT.GET_ALL_ROLES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: ACCESS_MANAGEMENT.GET_ALL_ROLES_FAILURE, payload: error });
    dispatch(
      setAlert({
        title: 'Failed to fetch roles',
        status: 'error'
      })
    );
  }
};

export const createUser = (userData) => async (dispatch) => {
  try {
    const response = await api.accessManagement.createUser(userData);
    dispatch({
      type: ACCESS_MANAGEMENT.CREATE_USER_SUCCESS,
      payload: "Success"
    });
    return response.data;
  } catch (error) {
    dispatch({
      type: ACCESS_MANAGEMENT.CREATE_USER_FAILURE,
      payload: error.message
    });
    throw error;
  }
};