import { createUser } from "../../store/actions/accessManagementActions";

export default function accessManagement(fetch, baseUrl) {
    return {
    getAllUserPermissions: () => fetch.get(`${baseUrl}/users`),
    updateUserPermissions: (userId, data) => fetch.post(`${baseUrl}/update/${userId}`, data),
    getAllRoles: () => fetch.get(`${baseUrl}/all-roles`),
    createUser: (userData) => fetch.post(`${baseUrl}/create-user`, userData),
   };
    
  }