import React, { useEffect, useState } from 'react';
import {
  Box,
  Flex,
  VStack,
  Heading,
  Spinner,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  useDisclosure,
  Container,
  Button,
  useColorModeValue
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import ContentLayout from '../../layouts/ContentLayout';
import { getAllUserPermissions } from '../../store/actions/accessManagementActions';
import UserPermissionsModal from './UserPermissionsModal';
import CreateUserModal from './CreateUserModal';
import { AiFillPlusCircle } from 'react-icons/ai';

const AccessManagement = () => {
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const accessManagement = useSelector((state) => state.accessManagement) || {};
  const { userPermissions = [], isLoading = false } = accessManagement;
  const { isOpen: isCreateModalOpen, onOpen: onCreateModalOpen, onClose: onCreateModalClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(getAllUserPermissions());
  }, [dispatch]);

  const handleUserClick = (user) => {
    setSelectedUser(user);
    onOpen();
  };

  const handleCloseModal = () => {
    onClose();
    setSelectedUser(null);
  };

  const bgColor = 'rgba(0, 0, 0, 0.3)';
  const borderColor = 'whiteAlpha.300';
  const textColor = 'whiteAlpha.900';
  const headerBg = 'rgba(0, 0, 0, 0.5)';
  const hoverBg = 'rgba(255, 255, 255, 0.1)';

  return (
    <ContentLayout>
      <Container maxW="container.xl">
        <VStack spacing={6} align="stretch">
          <Flex justifyContent="space-between" alignItems="center" mb={6}>
            <Heading size="lg" color="brand.500">Access Management</Heading>
            <Button
              onClick={onCreateModalOpen}
              colorScheme="brand"
              leftIcon={<AiFillPlusCircle />}
              color="black"
              size="md"
              mt={2}
            >
              Create User
            </Button>
          </Flex>
          <Box
            bg={bgColor}
            borderRadius="lg"
            borderWidth="1px"
            borderColor={borderColor}
            overflow="hidden"
          >
            {isLoading ? (
              <Flex justify="center" align="center" h="400px">
                <Spinner size="xl" color="brand.500" />
              </Flex>
            ) : (
              <Box overflowX="auto" overflowY="auto" maxHeight="calc(100vh - 200px)">
                <Table variant="unstyled" size="sm">
                  <Thead position="sticky" top={0} zIndex={1}>
                    <Tr bg={headerBg}>
                      <Th color={textColor}>Name</Th>
                      <Th color={textColor}>User ID</Th>
                      <Th color={textColor}>Roles</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {userPermissions.map((user) => (
                      <Tr
                        key={user.userId}
                        onClick={() => handleUserClick(user)}
                        cursor="pointer"
                        _hover={{ bg: hoverBg }}
                        transition="background-color 0.2s"
                      >
                        <Td color={textColor}>{user.name}</Td>
                        <Td color={textColor}>{user.userId}</Td>
                        <Td>
                          <Flex wrap="wrap" gap={2}>
                            {user.roles.map((role) => (
                              <Badge key={role} colorScheme="brand" variant="subtle">
                                {role}
                              </Badge>
                            ))}
                          </Flex>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Box>
            )}
          </Box>
        </VStack>
      </Container>
      <UserPermissionsModal
        user={selectedUser}
        isOpen={isOpen}
        onClose={handleCloseModal}
      />
      <CreateUserModal isOpen={isCreateModalOpen} onClose={onCreateModalClose} />
    </ContentLayout>
  );
};

export default AccessManagement;
